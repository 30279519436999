
import { useStore } from '@/store';
import { mapActions, mapState } from 'pinia';
import { defineComponent } from 'vue';
import Button from '@/components/ui/Button.vue';
import { ServerState } from '@shared/types';

export default defineComponent({
  name: 'Settings',
  components: { Button },
  data: () => ({
    password: '',
    error: false,
    dirty: false,
  }),
  created() {
    if (
      this.mode === 'admin' &&
      !this.userModeChangeError &&
      this.serverState
    ) {
      this.$router.replace('/admin');
    }
  },
  computed: {
    ...mapState(useStore, ['mode', 'userModeChangeError', 'serverState']),
  },
  methods: {
    ...mapActions(useStore, ['setUserMode']),
    login() {
      this.setUserMode('admin', this.password);
    },
  },
  watch: {
    userModeChangeError(to: boolean) {
      if (to && this.mode === 'admin' && this.dirty) {
        this.error = true;
      }
    },
    password() {
      this.error = false;
      this.dirty = true;
    },
    serverState(to: ServerState | null) {
      if (to === null) return;

      if (this.dirty) {
        sessionStorage.setItem('admin-password', this.password);
      }
      this.$router.replace('/admin');
    },
  },
});
